<template>
  <div>
    <v-container>
      <h1 class="pb-6">Register/Edit Livestock</h1>

      <v-row>
        <v-col>
          <v-card rounded="lg" class="pa-6">
            <v-container>
              <v-row class="d-flex justify-space-between pb-8">
                <div class="pb-2">
                  <v-btn
                    to="/register-new-livestock"
                    class="mr-2 mb-2"
                    depressed
                    color="primary"
                  >
                    <v-icon left dark>mdi-cow</v-icon>
                    Register New Livestock
                  </v-btn>
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-pencil</v-icon>
                    Edit Livestock Profile
                  </v-btn>
                </div>
                <div class="pb-2">
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-eye</v-icon>
                    View
                  </v-btn>
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-content-copy</v-icon>
                    Copy
                  </v-btn>
                </div>
              </v-row>

              <v-data-table
                :headers="headers"
                :items-per-page="5"
              ></v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "ID Tag #",
          align: "start",
          value: "1",
        },
        { text: "Livestock Type", value: "2" },
        { text: "Date of Birth", value: "3" },
        { text: "Date of Register", value: "4" },
      ],
    };
  },
};
</script>

<style></style>
